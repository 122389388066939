import React from "react";
import Header from "./components/header/header";
import Section from "./components/section/section";
import ProductSection from "./components/sections/product-section";
import ApplicationSection from "./components/sections/application-section";
import Footer from "./components/footer/footer";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <div className="landing" id="home">
          <div className="landing__content">
            <h1 className="landing__header">New generation bimetallic tubes</h1>
            <div className="landing__description">
              Etubes B.V. is a manufacturer of cladded and lined bimetallic
              steel tubes. Claddings and liners are applied to prevent tubes
              from corrosive, erosive or abrasive attack.
            </div>
          </div>
        </div>

        <Section
          title="About us"
          id="about-us"
          description={
            <p>
              Etubes B.V. is a manufacturer of cladded and lined bimetallic
              steel tubes. Claddings and liners are applied to prevent tubes
              from corrosive, erosive or abrasive attack.
            </p>
          }
          centered
        />

        <Section
          title="Bimetallic tube technology"
          description={
            <p>
              The technology developed by Etubes enables us to produce
              bimetallic tubes fully metallurgical bonded that are a better
              substitute for welded overlay, laser cladded and metallurgical
              cladded plate to tube. Besides that, new area of application can
              be explored due to the high proven quality and uncommon material
              combinations.
            </p>
          }
          row
        >
          <img
            src={require("./assets/tube.jpg")}
            alt="etubes-bimetallic tube"
            className="section__image"
          ></img>
        </Section>

        <Section
          title="Unique selling points"
          description={
            <React.Fragment>
              <ul>
                <li>80% of metal combinations are possible</li>
                <li>The best pure bonding</li>
                <li>In and outside tube cladding</li>
                <li>No limits on dimensions</li>
                <li>Competitive prices due to cost effect production method</li>
              </ul>
              <p>
                Several scientific research reports, performed by research
                institutes and potential customers, substantiates the statements
                about quality and possibilities. Each customer requires
                different material combinations and/or dimensions. Etubes did
                research for application in the waste-to-energy, oil & gas and
                chemical & nuclear.
              </p>
            </React.Fragment>
          }
          rowReverse
        >
          <img
            src={require("./assets/paper.jpg")}
            alt="etubes-usp"
            className="section__image"
          ></img>
        </Section>

        <ProductSection />

        <Section
          title="Engineering Solutions"
          description={
            <React.Fragment>
              <p>
                High alloy materials have been developed that perform
                successfully in different processes and against different kinds
                of attack. The cost of such alloys necessitates a high degree of
                efficiency in their application.
              </p>
              <p>
                Traditional cladding techniques utilize arc-welding for applying
                the cladding layers. This process causes dilution of the
                cladding with the base-tube and therefore has an impact on the
                properties of the cladding material. For internal liners, the
                dimensions of the welding equipment put considerable constraints
                to the base-tube dimensions.
              </p>
              <p>
                The Etubes-BiMetallic tubes provide a significant engineering
                solution. The two distinct factors that determine the selection
                of material for an application are: the medium it will be
                exposed to and the mechanical requirements of the function. For
                both duties the optimum material can be selected without
                compromising on durability. The two-phase bonding of the
                materials enables the engineer to make the most efficient use of
                materials without the need to allow for the degradation of
                material properties as a result of dilution.
              </p>
            </React.Fragment>
          }
          row
        >
          <img
            src={require("./assets/tubes.jpg")}
            alt="etubes-tubes"
            className="section__image"
          ></img>
        </Section>

        <ApplicationSection />
      </main>
      <Footer />
    </div>
  );
}

export default App;
