import React from "react";
import $ from "jquery";
import "./header.scss";

const Header = () => {
  let didScroll;
  let lastScrollTop = 0;

  $(window).scroll(function(event) {
    didScroll = true;
  });

  setInterval(function() {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);

  function hasScrolled() {
    let scrollTop = $(window).scrollTop();

    if (Math.abs(lastScrollTop - scrollTop) <= 5) return;

    if (
      scrollTop > lastScrollTop &&
      scrollTop > $(".navigation").outerHeight()
    ) {
      $(".navigation").addClass("navigation--up");
    } else {
      if (scrollTop + $(window).height() < $(document).height()) {
        $(".navigation").removeClass("navigation--up");
      }
    }
    lastScrollTop = scrollTop;
  }

  return (
    <header>
      <nav className="navigation">
        <a className="navigation__logo" href="#home">
          etubes
        </a>
        <ul className="navigation__list">
          <li className="navigation__item">
            <a className="navigation__item-anchor" href="#about-us">
              About us
            </a>
          </li>
          <li className="navigation__item">
            <a className="navigation__item-anchor" href="#products">
              Products
            </a>
          </li>
          <li className="navigation__item">
            <a className="navigation__item-anchor" href="#contact">
              Contact
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
