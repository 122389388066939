import React from "react";
import Icon from "@mdi/react";
import "./grid-item.scss";

const GridIcon = ({ icon }) => {
  return icon.set === "mdi" ? (
    <Icon path={icon.path} size={"30px"} color="black" />
  ) : (
    <i className="material-icons">{icon.path}</i>
  );
};

const GridItem = ({ icon, title, description }) => {
  return (
    <div className="grid__item">
      <GridIcon icon={icon} />
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

export default GridItem;
