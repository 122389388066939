import React from "react";
import "./section.scss";

const variant = ({ centered, row, rowReverse }) => {
  if (centered) return " section--centered";
  if (row) return " section--row";
  if (rowReverse) return " section--row-reverse";
  return "";
};

const Section = ({ title, description, ...props }) => {
  return (
    <section id={props.id} className={`section`.concat(variant(props))}>
      <div className="section__text">
        <h2>{title}</h2>
        {description}
      </div>
      {props.children}
    </section>
  );
};

export default Section;
