import React from "react";
import Waves from "../waves";

import "./product.scss";

const Product = ({
  shouldTransform,
  idx,
  childrenTop,
  childrenBottom,
  colorTop,
  colorBottom
}) => {
  const productElements = document.getElementsByClassName("product");
  const topHalfElements = document.getElementsByClassName("product__half--top");

  if (shouldTransform) {
    for (let i = 0; i < productElements.length; i++) {
      productElements[i].classList.add("fade-in");
    }
    for (let i = 0; i < topHalfElements.length; i++) {
      topHalfElements[i].classList.add("slide-down");
    }
  }

  return (
    <div className={`product product--${idx}`}>
      <div
        className={`product__half product__half--top product__half--${colorTop}`}
      >
        <div className="product__contents">
          {childrenTop.map((child, key) => (
            <span key={key}>{child}</span>
          ))}
        </div>
        <Waves color={colorTop} top />
      </div>

      <div
        className={`product__half product__half--bottom product__half--${colorBottom}`}
      >
        <Waves color={colorBottom} />
        <div className="product__contents">
          {childrenBottom.map((child, key) => (
            <span key={key}>{child}</span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Product;
