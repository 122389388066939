import React from "react";
import { useInView } from "react-intersection-observer";
import Section from "../section/section";
import Product from "../product/product";

const ProductSection = () => {
  const [ref, inView] = useInView({
    threshold: 0
  });

  return (
    <Section
      title="Products"
      id="products"
      description={
        <p>
          Etubes manufactures bimetallic tubes to the following specifications
        </p>
      }
      centered
    >
      <div className="section__columns">
        <div className="column">
          <Product
            shouldTransform={inView}
            idx={1}
            colorTop="d8d8d8"
            colorBottom="888888"
            childrenTop={[
              "Copper",
              "Duplex steel",
              "Titanium",
              "Nickel",
              "Zirconium",
              "Nickel alloys",
              "Copper alloys",
              "Tantalum",
              "Aluminium",
              "Stainless steel"
            ]}
            childrenBottom={[
              "Carbon steel",
              "Alloy steel",
              "Aluminium",
              "Stainless steel",
              "Copper"
            ]}
          />
          <h3 ref={ref}>Etubes-BiMetallic Cladded Tube</h3>
          <p>
            The Etubes-BiMetallic cladded tube is a carbon/stainless steel-based
            tube with a high-alloy cladding. The production process establishes
            a full metallic, two-phase bonding between the materials by using an
            Explosive Welding process.
          </p>
          <p>
            The tubes are typically applied in coal fired boilers and
            waste-to-energy plants to withstand the corrosive and erosive impact
            of the flue gases. The metallic bonding ensures the optimal cooling
            of the cladding and allows for the manufacturing preparation of
            return-bends up to 1.2D. Welding procedures are available for
            circumferential welded joints. Typical cladding materials are
            Inconel 625, Inconel 686 and various Hastelloy grades applied on
            A106, 16Mo5 or higher grade base-tubes. Cladding materials can be
            applied from 1mm to 10mm thickness depending on the base-tube
            dimensions.
          </p>
        </div>
        <div className="column">
          <Product
            shouldTransform={inView}
            idx={2}
            colorTop="888888"
            colorBottom="d8d8d8"
            childrenTop={[
              "Carbon steel",
              "Alloy steel",
              "Aluminium",
              "Stainless steel",
              "Copper"
            ]}
            childrenBottom={[
              "Copper",
              "Duplex steel",
              "Titanium",
              "Nickel",
              "Zirconium",
              "Nickel alloys",
              "Copper alloys",
              "Tantalum",
              "Aluminium",
              "Stainless steel"
            ]}
          />
          <h3>Etubes-BiMetallic Lined Tube</h3>
          <p>
            The Etubes-BiMetallic lined tube is a carbon steel-based tube with a
            high-alloy liner. The production process establishes a full
            metallic, two-phase bonding between the materials by using an
            Explosive Welding process. The tubes are typically applied in
            heat-exchanging equipment such as Syngas coolers and Claus sulphur
            recovery boilers, for the manufacturing of returnbends in crude
            heaters and for ethylene furnaces.
          </p>
        </div>
      </div>
    </Section>
  );
};

export default ProductSection;
