import React from "react";

const Waves = ({ color, ...props }) => {
  return props.top ? (
    <svg width="100%" height="20px">
      <g>
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${`#${color}`}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
                     translate(400 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(800 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(1200 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(1600 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(2000 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(2400 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(2800 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(3200 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(3600 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(4000 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(4400 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(4800 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(5200 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(5600 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(6000 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(6400 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(6800 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(7200 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(7600 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(8000 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(8400 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(8800 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(9200 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(9600 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(10000 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(10400 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(10800 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(11200 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(11600 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(12000 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(12400 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(12800 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(13200 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(13600 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(14000 0)"
        />
      </g>
    </svg>
  ) : (
    <svg width="100%" height="20px">
      <g>
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
                     translate(-200 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
                     translate(200 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(600 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(1000 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(1400 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(1800 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(2200 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(2600 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(3000 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(3400 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(3800 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(4200 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(4600 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(5000 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(5400 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(5800 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(6200 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(6600 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(7000 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(7400 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(7800 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(8200 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(8600 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(9000 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(9400 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(9800 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(10200 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(10600 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(11000 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(11400 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(11800 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(12200 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(12600 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(13000 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(13400 0)"
        />
        <path
          d="M0,30 Q100,0 200,30 T400,30 v30 h-400 Z"
          stroke={`#${color}`}
          fill={`#${color}`}
          transform="scale(0.06, 0.4)
          translate(13800 0)"
        />
      </g>
    </svg>
  );
};

export default Waves;
