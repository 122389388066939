import React from "react";
import Section from "../section/section";
import GridItem from "../grid-item/grid-item";
import { mdiFlask } from "@mdi/js";

const applications = [
  {
    name: "Oil / Gas",
    description:
      "separators, absorbers, slug catchers, flowlines, pipelines, catenary risers (SCR), fittings",
    icon: { set: "material", path: "local_gas_station" }
  },
  {
    name: "Chemical Industry",
    description: "columns, pressure vessels, reactors, heat exchangers",
    icon: { set: "mdi", path: mdiFlask }
  },
  {
    name: "Fertilizer Industry (Urea, Ammonia)",
    description: "strippers, reactors, heat exchangers",
    icon: { set: "material", path: "local_florist" }
  },
  {
    name: "Refineries / Petrochemical Industry",
    description:
      "columns, reactors, fractionators, vacuum, towers, distillation towers, hydrocrackers, hydrosulfurisers, coke drums",
    icon: { set: "material", path: "domain" }
  },
  {
    name: "Power Generation",
    description:
      "condenser tube sheets, geothermal plants, coal gasification, hydroelectricity (dam gates, shield plates) nuclear plant components, biofuels",
    icon: { set: "material", path: "offline_bolt" }
  },
  {
    name: "Marine / Defense",
    description: "protective layer for barrels, triclad for shipbuilding",
    icon: { set: "material", path: "security" }
  },
  {
    name: "Environmental Technology",
    description:
      "flue gas desulphurization (flue gas channels, chimney, scrubbers, ducts) garbage incineration plants",
    icon: { set: "material", path: "eco" }
  },
  {
    name: "Seawater Desalination",
    description:
      "pipes, heat exchangers evaporator shells, water boxes, pump vessels, flash chambers",
    icon: { set: "material", path: "waves" }
  },
  {
    name: "Pulp & Paper",
    description:
      "pulp boilers, bleaching plants, steaming vessels, digesters, evaporators, evaporator pipe plates",
    icon: { set: "material", path: "menu_book" }
  },
  {
    name: "Food Industry",
    description:
      "boiling pans for breweries, equipment for industrial kitchens, fermenters, premium induction cookware",
    icon: { set: "material", path: "restaurant" }
  },
  {
    name: "Shipbuilding",
    description: "chemical tankers, ice breakers, propeller systems",
    icon: { set: "material", path: "directions_boat" }
  },
  {
    name: "Others",
    description:
      "e.g. transition joints, hydrometallurgy (reactors) metallurgy (electro galvanizing rolls, nitriding salt baths), electrical equipment",
    icon: { set: "material", path: "blur_on" }
  }
];

const ApplicationSection = () => {
  return (
    <Section title="Range of Application" centered>
      <div className="section__grid">
        {applications.map((application, i) => (
          <GridItem
            title={application.name}
            description={application.description}
            icon={application.icon}
            key={i}
          />
        ))}
      </div>
    </Section>
  );
};

export default ApplicationSection;
