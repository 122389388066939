import React from "react";
import Section from "../section/section";
import "./footer.scss";

function scrollToTop() {
  window.scrollTo({ top: 0 });
}

const Footer = () => {
  return (
    <footer id="contact">
      <Section
        title="Let's talk"
        description={
          <p>Please click on the contact button to get in contact with us!</p>
        }
        row
      >
        <button
          onClick={() =>
            window.open("mailto:ericvaneijkeren@energetictubes.com")
          }
          className="button--contact"
        >
          Contact
          <i className="material-icons">email</i>
        </button>
      </Section>
      <button className="button--navigate" onClick={() => scrollToTop()}>
        <i className="material-icons">keyboard_arrow_up</i>
      </button>
    </footer>
  );
};

export default Footer;
